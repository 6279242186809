export const stringify = (value: object): string => {
  return JSON.stringify(value, null, 2);
};

/**
 * Receives a string and safely parses it as JSON, returning null
 * in case the string is not a valid JSON
 *
 * @param stringifiedObj - stringified version of an object
 * @returns the JSON representation of the object
 */
export const safeParseJSON = <TObject extends object>(
  stringifiedObj = '',
): TObject | null => {
  try {
    return JSON.parse(stringifiedObj);
  } catch (error) {
    return null;
  }
};

export const deepCopy = <T>(arr: T): T => {
  return JSON.parse(JSON.stringify(arr));
};
