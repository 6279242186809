import React, { useMemo } from 'react';
import type { GetBrandQuery } from '@quno/patient-journey/src/contentful/generated/graphql';

export type BrandSettings = {
  isPaymentEnabled?: boolean;
  isFlightTicketUploadEnabled?: boolean;
  isClinicInformationEnabled?: boolean;
  isDoctorsEnabled?: boolean;
  isHotelInformationEnabled?: boolean;
  isMarketplaceListingEnabled?: boolean;
  isTreatmentInformationEnabled?: boolean;
  patientJourneyDomain?: string;
  allowJunoId?: boolean;
};

export type BrandTranslations = {
  onboardingComment?: string;
  onboardingCommentOpportunity?: string;
  startPageHeadlineOverride?: string;
  richTextStart?: string;
  richTextStartOpportunity?: string;
  quoteMarkdownOverride?: string;
  itineraryBeforePaymentOverride?: string;
  itineraryAfterPaymentOverride?: string;
  doctorCommentIntroOverride?: string;
  quoteHeadlineOverride?: string;
  quoteHeadlinePluralOverride?: string;
  paymentName?: string;
  paymentDescription?: string;
  continueModalOverride?: string;
  confirmBookingModalOverride?: string;
  bookingHeadlineOverride?: string;
  menuLinks: string;
};

export type BrandContextType =
  | ({
      settings?: BrandSettings;
      theme?: BrandTheme;
      translations?: BrandTranslations;
    } & GetBrandQuery['brand'])
  | null;

const BrandContext = React.createContext<BrandContextType>(null);

export default BrandContext;

export type FontConfig = {
  url: string;
  fontFamily: string;
};

export type BrandTheme = {
  fontFamily?: 'Inter';
  primaryColor?: string;
  secondaryColor?: string;
  backgroundColorDark?: string;
  backgroundColorLight?: string;
  h1FontFamily?: FontConfig | string;
  h2FontFamily?: FontConfig | string;
  h3FontFamily?: FontConfig | string;
  h4FontFamily?: FontConfig | string;
  h5FontFamily?: FontConfig | string;
  h6FontFamily?: FontConfig | string;
  textFontFamily?: FontConfig | string;
  buttonFontFamily?: FontConfig | string;
};

const getFontFamily = (
  fontFamily?: FontConfig | string,
  defaultFontFamily?: string,
): string => {
  if (typeof fontFamily === 'string') {
    return `${fontFamily} !important`;
  }

  if (fontFamily?.fontFamily) {
    return `${fontFamily.fontFamily} !important`;
  }

  return defaultFontFamily || 'Inter';
};

const getFontFamilyUrls = (theme: BrandTheme): FontConfig[] => {
  return Array.from(
    new Set(
      [
        theme?.h1FontFamily,
        theme?.h2FontFamily,
        theme?.h3FontFamily,
        theme?.h4FontFamily,
        theme?.h5FontFamily,
        theme?.h6FontFamily,
        theme?.textFontFamily,
        theme?.buttonFontFamily,
      ].filter(
        (fontFamily) => typeof fontFamily !== 'string' && fontFamily?.url,
      ),
    ),
  ) as FontConfig[];
};

export const importFonts = (fontFamilies: FontConfig[]): string => {
  // Separate the fonts into @import and @font-face groups
  const regex = /\.(woff|woff2|ttf|otf)$/;

  const importFonts = fontFamilies
    .filter((font) => !regex.test(font.url))
    .map((font) => `@import url('${font.url}');`);

  const fontFaceFonts = fontFamilies
    .filter((font) => regex.test(font.url))
    .map(
      (font) =>
        `@font-face { font-family: ${font.fontFamily}; src: url('${font.url}'); }`,
    );

  // Concatenate @import URLs before @font-face rules
  return [...importFonts, ...fontFaceFonts].join('\n');
};

export const BrandProvider = ({
  brand,
  children,
}: {
  brand: BrandContextType;
  children: JSX.Element;
}): JSX.Element => {
  const fontFamilyWithUrls = getFontFamilyUrls(brand?.theme || {});
  const fontImports = importFonts(fontFamilyWithUrls);

  const styleContent = useMemo(
    () => `
    ${fontImports}

    :root {
      --theme-font-family: ${brand?.settings?.fontFamily || 'Inter'};
      --theme-spacing: 1rem;

      --theme-primary-color: ${brand?.settings?.primaryColor || '#00BCB3'};
      --theme-secondary-color: ${brand?.settings?.secondaryColor || '#00828B'};
      --theme-background-color-dark: ${
        brand?.settings?.backgroundColorDark || '#1C475F'
      };
      --theme-background-color-light: ${
        brand?.settings?.backgroundColorLight || '#EEF9F7'
      };
      --theme-h1-font-family: ${getFontFamily(
        brand?.theme?.h1FontFamily,
        'var(--theme-font-family)',
      )};
      --theme-h2-font-family: ${getFontFamily(
        brand?.theme?.h2FontFamily,
        'var(--theme-font-family)',
      )};
      --theme-h3-font-family: ${getFontFamily(
        brand?.theme?.h3FontFamily,
        'var(--theme-font-family)',
      )};
      --theme-h4-font-family: ${getFontFamily(
        brand?.theme?.h4FontFamily,
        'var(--theme-font-family)',
      )};
      --theme-h5-font-family: ${getFontFamily(
        brand?.theme?.h5FontFamily,
        'var(--theme-font-family)',
      )};
      --theme-h6-font-family: ${getFontFamily(
        brand?.theme?.h6FontFamily,
        'var(--theme-font-family)',
      )};
      --theme-text-font-family: ${getFontFamily(
        brand?.theme?.textFontFamily,
        'var(--theme-font-family)',
      )};
      --theme-button-font-family: ${getFontFamily(
        brand?.theme?.buttonFontFamily,
        'var(--theme-font-family)',
      )};
      --theme-white: #FFFFFF;
      --theme-dark-gray: #15202B;
      --theme-medium-gray: #929292;
      --theme-light-gray: #E6E6E6;
    }`,
    [brand, fontImports],
  );

  const styleTag = useMemo(
    () => <style dangerouslySetInnerHTML={{ __html: styleContent }} />,
    [styleContent],
  );

  return (
    <>
      {styleTag}
      <BrandContext.Provider value={brand}>{children}</BrandContext.Provider>
    </>
  );
};
